<template>
  <v-container id="alerts" fluid tag="section">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <v-row justify="center"
      ><!-- add or ediit trend block begins-->
      <v-dialog v-model="toggleAddEditTrend" max-width="600" persistent>
        <template>
          <v-card>
            <v-form
              ref="addReportForm"
              v-model="toggleAddEditTrend"
              lazy-validation
            >
              <v-card-title class="text-h5 grey lighten-2">
                <b style="font-size: 22px !important"
                  >{{ isEdit ? "UPDATE TREND INFO" : "ADD TREND INFO" }}
                </b>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="specificTrendData.trend_name">
                        <template v-slot:label>
                          <span
                            >Trend Title <span id="required"> * </span></span
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="specificTrendData.documentLink.length > 0 ? 6 : 12"
                    >
                      <v-file-input
                        accept=".pdf,.ppt,.pptx,.docx,.csv"
                        v-model="specificTrendData.documentState"
                      >
                        <template v-slot:label>
                          <span>Document <span id="required"> * </span></span>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col
                      cols="6"
                      style="padding-top: 30px"
                      v-if="specificTrendData.documentLink.length > 0"
                    >
                      <a :href="specificTrendData.documentLink" target="_blank">
                        {{ specificTrendData.documentName }}
                      </a>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -3%">
                    <v-col cols="6">
                      <span id="required">
                        <h6>
                          [Hint: Allowed extension(.pdf,.pptx,.docx,.csv)]
                        </h6>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="all_audience"
                        v-model="specificTrendData.audience_name"
                        item-text="mPanelName"
                        item-value="mPanelID"
                        attach
                        chips
                        multiple
                      >
                        <template v-slot:label>
                          <span>Audience <span id="required"> * </span></span>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 2">
                            <span>{{ item.mPanelName }}</span>
                          </v-chip>
                          <span
                            v-if="index === 2"
                            class="text-grey text-caption align-self-center"
                          >
                            (+{{ specificTrendData.audience_name.length - 2 }}
                            others)
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row id="product__rowpadding">
                    <!-- <v-col
                      :cols="4"
                      class="label-field text-left no-right no-bottom"
                      >Sector<span id="approved"> * </span>/Category<span
                        id="approved"
                      >
                        *
                      </span>
                      /Sub Category :
                    </v-col> -->
                    <v-col
                      :cols="12"
                      class="text-left no-left no-bottom no-top"
                    >
                      <div v-if="sectorDatas.length > 0">
                        <ul>
                          <li v-for="(c, i) in sectorDatas" v-bind:key="i">
                            <span v-for="(el, index) in c" v-bind:key="index">
                              <strong v-if="index == 0">{{ el.name }}</strong>
                              <strong v-else>/ {{ el.name }}</strong>
                            </span>
                            <!-- <span
                              style="position: static; cursor: pointer"
                              v-ripple="{ class: `primary--text` }"
                              @click.prevent="
                                traverseSectorCombinations(i, true)
                              "
                            >
                              Up</span
                            >
                            <span
                              style="position: static; cursor: pointer"
                              v-ripple="{ class: `primary--text` }"
                              @click.prevent="
                                traverseSectorCombinations(i, false)
                              "
                            >
                              Down</span
                            > -->

                            <span
                              style="
                                position: static;
                                cursor: pointer;
                                color: #3f51b5;
                              "
                              v-ripple="{ class: `primary--text` }"
                              @click.prevent="removeSectorCombination(i)"
                            >
                              Remove</span
                            >
                          </li>
                        </ul>
                      </div>

                      <v-select
                        v-model="selected_sector"
                        v-show="showAddSectorName"
                        color="purple darken-2"
                        :items="sectors"
                        item-text="sectorName"
                        item-value="sectorID"
                        outlined
                        dense
                        @change="findCateg(selected_sector)"
                      >
                        <template v-slot:label>
                          <span>Sector <span id="required"> * </span></span>
                        </template></v-select
                      >
                      <v-select
                        v-if="selected_sector"
                        v-model="selected_category"
                        :items="secCategory"
                        v-show="showAddSectorName"
                        color="purple darken-2"
                        label="Category*"
                        outlined
                        dense
                        @change="findSubCateg(selected_category)"
                      ></v-select>
                      <v-select
                        v-if="selected_category"
                        v-model="selected_subcategory"
                        :items="secSubCategory"
                        v-show="showAddSectorName"
                        color="purple darken-2"
                        label="Sub Category"
                        @change="findSubSubCateg(selected_subcategory)"
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        v-if="selected_subcategory"
                        v-model="selected_subsubcategory"
                        :items="secSubSubCategory"
                        v-show="showAddSectorName"
                        color="purple darken-2"
                        label="Sub Sub Category"
                        outlined
                        dense
                      ></v-select>
                      <v-btn
                        v-if="selected_sector"
                        @click="showSectorData()"
                        color="blue darken-1"
                        >Add</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-radio-group
                        v-model="specificTrendData.countrySelectedModel"
                        row
                      >
                        <span style="margin-right: 5px"
                          >Country
                          <span> <span id="required"> * </span></span>:</span
                        >
                        <v-radio label="UNITED STATES" value="1"></v-radio>
                        <v-radio label="CANADA" value="3"></v-radio>
                        <v-radio label="All" value="0" checked></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        v-model="specificTrendData.datesortmenu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        content-class="menu-position"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            no-title
                            v-model="specificTrendData.datesort"
                            label="Date Sort"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="specificTrendData.datesort"
                          @input="specificTrendData.datesortmenu = false"
                          no-title
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span v-if="!isEdit">
                  <v-btn
                    small
                    color="primary"
                    class="mr-4"
                    @click="addNewTrend"
                  >
                    Save
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn small color="primary" class="mr-4" @click="editTrend">
                    Update
                  </v-btn>
                </span>

                <v-btn
                  small
                  color="default"
                  @click="
                    toggleAddEditTrend = false;
                    previous_selected_sector_combination = [];
                    sectorDatas = [];
                  "
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog> </v-row
    ><!-- add or ediit trend block ends-->
    <v-row>
      <v-col cols="4" class="custom-input">
        <v-text-field v-model="trendTitle" label="Trend Title" />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4">
        <v-select
          :items="sector"
          v-model="selSectors"
          label="Sector"
          item-text="name"
          item-value="id"
          chips
          multiple
          v-on:change="sectorSelect($event, e)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ selSectors.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="selAudience"
          :items="all_audience"
          item-text="mPanelName"
          item-value="mPanelID"
          label="Audience"
          attach
          chips
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.mPanelName }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ selAudience.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4">
        <v-select
          :items="category_list"
          v-model="selCategory"
          item-text="name"
          item-value="id"
          label="Category"
          attach
          chips
          multiple
          v-on:change="selectCategory($event, e)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ selCategory.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  no-title
                  v-model="sdate"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="sdate"
                @input="menu2 = false"
                no-title
                scrollable
                ref="datepicker"
                event-color="white"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  no-title
                  v-model="edate"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="edate"
                @input="menu3 = false"
                no-title
                scrollable
                ref="datepicker"
                event-color="white"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4">
        <v-select
          :items="subcategory_list"
          v-model="selSubCategory"
          item-text="name"
          item-value="id"
          label="Sub Category"
          attach
          chips
          multiple
          v-on:change="selectSubCategory($event, e)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ selSubCategory.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-radio-group v-model="countrySelectedModel" row>
          <span style="margin-right: 5px">Country:</span>
          <v-radio label="UNITED STATES" value="1"></v-radio>
          <v-radio label="CANADA" value="3"></v-radio>
          <v-radio label="All" value="0" checked></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="subsubcategory_list"
          v-model="selSubSubCategory"
          item-text="name"
          item-value="id"
          label=" Sub Sub Category"
          attach
          chips
          multiple
          v-on:change="selectSubSubCategory($event, e)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="text-grey text-caption align-self-center"
            >
              (+{{ selSubSubCategory.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-btn small color="primary" class="mt-5" @click="search">
          Search
        </v-btn>
        <v-btn small color="default" class="mt-5" @click="reset"> Reset </v-btn>
      </v-col>
      <v-col cols="8">
        <div style="float: right">
          <v-btn small color="primary" class="mt-5" @click="addTrend()">
            Add Trend
          </v-btn>
          <v-btn small color="error" class="mt-5" @click="deleteTrend">
            Delete
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :single-select="singleSelect"
          v-model="selected"
          :items="items"
          :loading="isLoading"
          :items-per-page="perpage"
          show-select
          :options.sync="options"
          item-key="trend_id"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon medium color="grey" @click="updateMember($event, item)">
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.combinecategory="{ item }">
            <td>
              <span
                v-for="(element, index) in item.combinecategory"
                :key="index"
              >
                {{ element }}
                <br v-if="index !== item.combinecategory.length - 1" />
              </span>
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-4 pb-4">
          <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :perpage="perpage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  name: "TrendReport",
  components: {
    Loading,
    Pagination,
  },
  data: () => ({
    date: "",
    loading: false,
    sectorAPILoading: false,
    singleSelect: false,
    max: moment(new Date()).format("YYYY-MM-DD"),
    headers: [
      {
        text: "Title",
        value: "trend_name",
      },
      {
        text: "Audience",
        value: "audience_name",
        sortable: false,
        width: "25%",
      },
      {
        text: "Sector/Category/Subcategory",
        value: "combinecategory",
        sortable: false,
        width: "25%",
      },
      { text: "Date", value: "trend_date", sortable: false, width: "10%" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
    toggleAddEdit: false,
    toggleAddEditTrend: false,
    reportFile: null,
    selected: [],
    isLoading: false,
    fullPage: true,
    showPerPage: true,
    page: 1,
    emailAddress: "",
    companyName: "",
    options: {},
    sortby: "",
    countrySelectedModel: "0",
    sortDesc: false,
    sort: "asc",
    accStatus: "",
    // perpage: 50,
    trendTitle: "",
    selAudience: [],
    errorMsg: "",
    all_audience: [],
    all_sector: [],
    specificTrendData: {
      audience_name: [],
      trend_id: 0,
      // sector_name: "",
      // subcategory_name: "",
      documentState: null,
      documentName: "",
      documentLink: "",
      datesort: moment().subtract(1, "days").format("YYYY-MM-DD"),
      datesortmenu: false,
      countrySelectedModel: "0",
    },
    all_category: [],
    all_sub_category: [],
    all_sub_sub_category: [],
    sector: [],
    category_list: [],
    category: [],
    subcategory: [],
    subcategory_list: [],
    subsubcategory: [],
    subsubcategory_list: [],
    selSectors: [],
    selCategory: [],
    selSubCategory: [],
    selSubSubCategory: [],
    sectorDatas: [],
    categoryData: [],
    subcategoryData: [],
    selectAllSector: false,
    //Popup
    sectorData: [],
    selected_sector: "",
    selected_category: "",
    selected_subcategory: "",
    selected_subsubcategory: "",
    showAddSectorName: true,
    sectors: [],
    secCategory: [],
    secSubCategory: [],
    secSubSubCategory: [],
    addSectorId: [],
    allSectorIds: [],
    previous_selected_sector_combination: [],
    isEdit: false,
    validateAddReport: false,
    max: moment(new Date()).format("YYYY-MM-DD"),
    menu2: false,
    menu3: false,
    sdate: "",
    edate: "",
  }),
  async beforeMount() {
    await this.getSectorApi();
    await this.getRawData();
    // await this.getClientRawData();
  },
  async mounted() {
    await this.getuserClients();
  },
  watch: {
    selected: function (o) {},
    options: function (o) {
      console.log("WWWw", o);
      let sortby = o.sortBy[0];
      let direction = o.sortDesc[0];
      console.log("sortby", sortby, "direction", direction);
      if (!this.reset_flag) {
        if (sortby != undefined && direction != undefined) {
          this.sortby = sortby;
          if (o.page === 1) {
            this.page = 1;
          }
          if (direction) {
            this.sort = "desc";
          } else {
            this.sort = "asc";
          }

          this.getuserClients();
        }
      } else {
        this.reset_flag = false;
      }
    },
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    perpage: function () {
      return this.$store.getters["TrendReport/getPerPage"];
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    totRecords: function () {
      var store = this.$store;
      return store.getters["TrendReport/getTotal"] == ""
        ? 0
        : store.getters["TrendReport/getTotal"];
    },
    currentPage: function () {
      return this.$store.getters["TrendReport/getCurrentPage"];
    },
    lastPage: function () {
      return this.$store.getters["TrendReport/getLastPage"];
    },
    dmduplicates: function () {
      return this.allDmduplicates();
    },
    searchQuery: {
      get: function () {
        return this.$store.getters["TrendReport/getSearchQuery"];
      },
      set: function (o) {
        this.$store.dispatch("TrendReport/setSearchQuery", o);
      },
    },
    sesrchById: {
      get: function () {
        return this.$store.getters["TrendReport/getSearchById"];
      },
      set: function (o) {
        this.$store.dispatch("TrendReport/setSetSearchBySubject", false);
        this.$store.dispatch("TrendReport/setSearchQuery", "");
        this.$store.dispatch("TrendReport/setSearchById", o);
      },
    },
    searchBySubject: {
      get: function () {
        return this.$store.getters["TrendReport/setSetSearchBySubject"];
      },
      set: function (o) {
        this.$store.dispatch("TrendReport/setSearchById", false);
        this.$store.dispatch("TrendReport/setSearchQuery", "");
        this.$store.dispatch("TrendReport/setSetSearchBySubject", o);
      },
    },
  },
  methods: {
    getRawData() {
      let view = this;
      let rawdata = view.$url("ALL_RAWDATA");
      // let rawdata = "https://dev06.competiscan.com:5053/sector/372"
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .get(rawdata)
        .then(async (response) => {
          console.log("Raw data", response);
          view.all_audience = response.data.audience;
          view.all_sector = response.data.sectors;
          view.sectors = response.data.sectors;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSectorApi() {
      let view = this;
      view.isLoading = true;
      view.sectorAPILoading = true;
      console.log("Sector", JSON.parse(localStorage.getItem("sector")));
      // if (JSON.parse(localStorage.getItem("sector")) === null) {
      let sector_api = view.$url("ALLSECTORS") + "?type=sector";
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .get(sector_api)
        .then(async (response) => {
          console.log("Sector.", response);
          view.sector = response.data.payload;
          view.findAndPrintChildren(response.data.payload);
          view.sectorAPILoading = false;
          view.isLoading = view.sectorAPILoading;
        })
        .catch((error) => {
          console.error(error);
        });
      // }
    },
    async updateMember(e, items) {
      const view = this;
      view.isLoading = true;
      view.isEdit = true;
      view.specificTrendData.documentState = null;
      view.selected_sector = "";
      view.selected_category = "";
      view.selected_subcategory = "";
      view.selected_subsubcategory = "";

      try {
        const response = await view
          .$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: view,
          })
          .post(
            view.$url("EDIT_TREND_SEARCH"),
            JSON.stringify({
              trend_id: items.trend_id,
            })
          );

        const trendData = response.data.data[0];

        // Update trend specific data
        view.specificTrendData.datesort = trendData.trend_date;
        view.specificTrendData.trend_name = this.sanitizeInput(
          trendData.trend_name
        );
        view.specificTrendData.audience_name = trendData.audience_id;
        view.specificTrendData.countrySelectedModel =
          trendData.country_id.toString();

        // Process sector categories sequentially
        for (let i = 0; i < trendData.sector_id.length; i++) {
          await view.setEditSectorCategory(
            trendData.sector_id[i],
            trendData.category_id[i],
            trendData.subcategory_id[i],
            trendData.subtosubcategory_id[i]
          );
        }

        // Update remaining trend data
        view.specificTrendData.trend_id = trendData.trend_id;
        view.specificTrendData.documentLink = trendData.trend_link;
        view.specificTrendData.documentName = trendData.file_name;
        view.specificTrendData.filePath = trendData.file_path;
        view.toggleAddEditTrend = true;
      } catch (error) {
        console.error("Error in updateMember:", error);
      } finally {
        if (!view.sectorAPILoading) view.isLoading = false;
      }
    },

    async setEditSectorCategory(sec, cat, subcat, subsubcat) {
      const selectedCombination = [];

      // Build combination array
      if (sec != undefined && sec != 0) selectedCombination.push(sec);
      if (cat != undefined && cat != 0) selectedCombination.push(cat);
      if (subcat != undefined && subcat != 0) selectedCombination.push(subcat);
      if (subsubcat != undefined && subsubcat != 0)
        selectedCombination.push(subsubcat);

      try {
        // Initialize arrays if they don't exist
        if (!this.sectorDatas) this.sectorDatas = [];
        if (!this.previous_selected_sector_combination) {
          this.previous_selected_sector_combination = [];
        }

        const response = await this.$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: this,
        }).post(this.$url("COMPANYSEC"), {
          selectedIds: selectedCombination,
        });

        const allSectors = response.data.payload[0];
        const combination = allSectors.map((sector) => ({
          id: sector.id,
          name: sector.name,
        }));

        this.sectorDatas.push(combination);
        this.previous_selected_sector_combination.push(selectedCombination);

        return { combination, selectedCombination };
      } catch (error) {
        console.error("Error in setEditSectorCategory:", error);
        throw error;
      }
    },
    setDocumentName() {
      let view = this;
      let url = view.specificTrendData.documentLink;
      let filename = url.split("/").pop();
      return filename;
    },
    addTrend() {
      let view = this;
      view.toggleAddEditTrend = true;
      view.isEdit = false;
      view.specificTrendData.audience_name = [];
      view.sectorDatas = [];
      view.selected_sector = "";
      view.selected_category = "";
      view.selected_subcategory = "";
      view.selected_subsubcategory = "";
      view.specificTrendData.country_name = "";
      view.specificTrendData.countrySelectedModel = "0";
      view.specificTrendData.documentState = null;
      view.specificTrendData.trend_id = 0;
      (view.specificTrendData.datesort = moment().format("YYYY-MM-DD")),
        (view.specificTrendData.trend_name = "");
      view.specificTrendData.documentState = null;
      view.specificTrendData.documentLink = "";
      view.specificTrendData.documentName = "";
      // view.$store.dispatch("TrendReport/isEdit", false);
      // view.$router.push("/add-member");
    },
    setCombinecategory() {
      let view = this;
      for (let i = 0; i < view.items.length; i++) {
        let combinecat = [];
        const item = view.items[i];
        item.sector_names.forEach((val) => {
          const {
            category_name,
            sector_name,
            subcategory_name,
            subtosubcategory_name,
          } = val;
          let catItems =
            sector_name +
            " / " +
            category_name +
            " / " +
            subcategory_name +
            " / " +
            subtosubcategory_name;
          combinecat.push(catItems.replaceAll("0", " "));
        });
        view.items[i]["combinecategory"] = combinecat;
      }
      // view.items.forEach((item)=>{

      // })
    },
    getuserClients() {
      let view = this;
      view.isLoading = true;
      let manage_reports_url = view.$url("ALL_TREND_REPORT");
      let config = {
        page_number: view.$store.getters["TrendReport/getRequestPayload"].page,
        page_size: view.$store.getters["TrendReport/getRequestPayload"].perpage,
      };
      config = this.setCriteria(config);
      console.log("config>>>>>>>>>>>",config);
      // view.$store
      //   .dispatch("TrendReport/setIsFromSearch", true)
      //   .then(function () {
      //     return view.$store.dispatch("TrendReport/setIsLoading", true);
      //   })
      //   .then(function () {
      //     return view.$store.dispatch("TrendReport/setResponsePayload", []);
      //   });
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .post(manage_reports_url, config)
        .then(async (res) => {
          console.log("status code >>>>>", res.status);
          view.items = await res.data.data;
          if (view.items !== undefined) {
            console.log("response>>>>>", view.items);
            this.setCombinecategory();
            if (!view.sectorAPILoading) view.isLoading = false;
            view.selected = [];
            let lastPage = 0;
            lastPage = Math.ceil(
              res.data.total_records /
                view.$store.getters["TrendReport/getPerPage"]
            );
            typeof view.items !== "undefined"
              ? view.$store.dispatch(
                  "TrendReport/setResponsePayload",
                  view.items
                )
              : [];
            //Total items
            // view.$store.dispatch("TrendReport/setTotal", 100);
            typeof res.data.total_records !== "undefined"
              ? view.$store.dispatch(
                  "TrendReport/setTotal",
                  res.data.total_records
                )
              : "";
            //Total pages
            typeof view.items !== "undefined"
              ? view.$store.dispatch("TrendReport/setLastPage", lastPage)
              : [];
          } else {
            if (!view.sectorAPILoading) view.isLoading = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dedupClass: function (i) {
      return parseInt(i) % 2 === parseInt(0)
        ? "grey lighten-2"
        : "grey lighten-5";
    },
    compareDate() {
      let view = this;
      let start_date = new Date(view.sdate);
      let end_date = new Date(view.edate);
      if (start_date > end_date) {
        return false;
      } else {
        return true;
      }
    },
    search: function () {
      let view = this;
      let searchingCriteria = {};
      let mulSelCategory = view.selCategory.map((e) => e.id);
      let mulSelSubCategory = view.selSubCategory.map((e) => e.id);
      let mulSelSubSubCategory = view.selSubSubCategory.map((e) => e.id);
      let page_size =
        view.$store.getters["TrendReport/getRequestPayload"].perpage ?? 10;
      console.log("pagesize", page_size);
      view.$store.dispatch("TrendReport/setTotal", 0);
      view.$store.dispatch("TrendReport/setLastPage", 0);
      console.log(
        "Sector",
        view.selSectors,
        "Sector.",
        mulSelCategory,
        "Sector..",
        mulSelSubCategory,
        "Sector...",
        mulSelSubSubCategory
      );
      Object.assign(searchingCriteria, {
        page_number: 1,
        page_size: page_size,
      });
      if (view.compareDate()) {
        if (
          view.trendTitle.length > 0 ||
          view.selAudience.length > 0 ||
          view.sdate.length >= 0 ||
          view.edate.length >= 0 ||
          view.countrySelectedModel.length > 0 ||
          view.selSectors.length > 0 ||
          mulSelCategory.length > 0 ||
          mulSelSubCategory.length > 0 ||
          mulSelSubCategory.length > 0 ||
          mulSelSubSubCategory.length > 0
        ) {
          this.$store.dispatch("TrendReport/setPerPage", page_size);
          this.$store.dispatch("TrendReport/setPage", parseInt(1));
          // this.getuserClients();

          searchingCriteria = this.setCriteria(searchingCriteria);

          console.log("Seaching payload.", searchingCriteria);
          view.getDataBySearch(searchingCriteria);
        } else {
          view.$swal.fire({
            icon: "warning",
            title: "Warning",
            text: "Please select any search field",
          });
        }
      } else {
        view.$swal.fire({
          icon: "warning",
          title: "Warning",
          text: "From date should be less than To date",
        });
      }
    },

    setCriteria(obj) {
      let view = this;
      let mulSelCategory = view.selCategory.map((e) => e.id);
      let mulSelSubCategory = view.selSubCategory.map((e) => e.id);
      let mulSelSubSubCategory = view.selSubSubCategory.map((e) => e.id);

      if (view.trendTitle.length > 0) {
        Object.assign(obj, {
          trend_name: view.trendTitle,
        });
      }
      if (view.selAudience.length > 0) {
        Object.assign(obj, {
          audience_id: view.selAudience,
        });
      }
      if (view.sdate.length > 0) {
        Object.assign(obj, {
          from_date: view.sdate,
        });
      }
      if (view.edate.length > 0) {
        Object.assign(obj, {
          to_date: view.edate,
        });
      }
      if (view.countrySelectedModel.length > 0) {
        Object.assign(obj, {
          country_id: view.countrySelectedModel,
        });
      }
      if (view.selSectors.length > 0) {
        Object.assign(obj, {
          sector_id: view.selSectors,
        });
      }
      if (mulSelCategory.length > 0) {
        Object.assign(obj, {
          category_id: mulSelCategory,
        });
      }
      if (mulSelSubCategory.length > 0) {
        Object.assign(obj, {
          subcategory_id: mulSelSubCategory,
        });
      }
      if (mulSelSubSubCategory.length > 0) {
        Object.assign(obj, {
          subtosubcategory_id: mulSelSubSubCategory,
        });
      }

      if (view.sortby) {
        console.log("sortby",view.sortby)
        Object.assign(obj, {
          sort_field: view.sortby,
        });

        if (view.sort.length > 0) {
        console.log("sort",view.sort)

          Object.assign(obj, {
            sort_order: view.sort,
          });
        }
      }
      return obj;
    },
    getDataBySearch(search) {
      let view = this;
      console.log(search, "ASSS.");
      let sectors = [];
      let localITems = [];
      let audience_id = [];
      view.isLoading = true;
      view.items = [];
      view
        .$fetch({ requiresAuth: true, operation: "add", vueScope: view })
        .post(view.$url("SEARCH_TREND"), JSON.stringify(search))
        .then((result) => {
          //view.items = result.data.data;
          if (result.data.data !== undefined && result.data.data !== null) {
            let total_record = result.data.total_records;
            let lastPage = Math.ceil(
              total_record / view.$store.getters["TrendReport/getPerPage"]
            );

            view.$store.dispatch("TrendReport/setTotal", total_record);
            view.$store.dispatch("TrendReport/setLastPage", lastPage);

            localITems = result.data.data;

            sectors = result.data.data.map((e) => e.sector_name);
            audience_id = result.data.data.map((e) => e.audience_name);
            console.log("audience id", audience_id);
            console.log("sectors", sectors);

            for (let i = 0; i < audience_id.length; i++) {
              localITems[i]["audience_name"] = audience_id[i];
            }

            view.items = localITems;
            view.setCombinecategory();
            typeof view.items !== "undefined"
              ? view.$store.dispatch(
                  "TrendReport/setResponsePayload",
                  view.items
                )
              : [];
            //Total items
          }
          if (!view.sectorAPILoading) view.isLoading = false;
          view.trend_name = "";
        })
        .catch((e) => {
          console.log(e);
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    //pagination methods
    getDataByPage(page) {
      let view = this;
      console.log("Data", page);
      view.$store
        .dispatch("TrendReport/setPage", page)
        .then(function () {
          return view.$store.dispatch("TrendReport/setIsLoading", true);
        })
        .then(function () {
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    handlePerPage(p) {
      let view = this;
      view.$store
        .dispatch("TrendReport/setPerPage", p)
        .then(function () {
          return view.$store.dispatch("TrendReport/setPage", parseInt(1));
        })
        .then(function () {
          return view.$store.dispatch("TrendReport/setIsLoading", true);
        })
        .then(function () {
          view.options.itemsPerPage = p;
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    paginate(n) {
      console.log(n);
      let view = this;
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let myPromise = new Promise(function (resolve, reject) {
        resolve(
          view.$store.dispatch(
            "TrendReport/setPage",
            n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n)
          )
        );
        reject("Error: on set page");
      });
      myPromise
        .then(function () {
          view.$store.getters["TrendReport/getIsFromSearch"]
            ? view.getuserClients()
            : view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    last() {
      let view = this;
      console.log("last", view.lastPage);
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
        return;
      }
      view.$store
        .dispatch("TrendReport/setPage", view.lastPage)
        .then(function () {
          view.$store.dispatch("TrendReport/setIsLoading", true);
        })
        .then(function () {
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    deleteTrend() {
      let view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove Trend",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let deleteTrend = [];
              if (!view.sectorAPILoading) view.isLoading = true;
              for (let i = 0; i < view.selected.length; i++) {
                deleteTrend.push(view.selected[i].trend_id);
              }
              console.log("Deleted IDs", deleteTrend);
              let client_basic_url =
                view.$url("DELETE_TREND_REPORT") +
                "?trend_ids=" +
                deleteTrend.join();

              this.$fetch({
                requiresAuth: true,
                operation: "view",
                vueScope: view,
              })
                .delete(client_basic_url)
                .then((res) => {
                  const response = res;
                  if (!view.sectorAPILoading) view.isLoading = false;
                  this.$swal
                    .fire({
                      icon: response.status == "Failed" ? "error" : "success",
                      title:
                        response.status == "Failed"
                          ? "Record cannot be Deleted"
                          : "Record Deleted",
                      text: response.message,
                    })
                    .then((res) => {
                      if (res.isConfirmed) {
                        view.selected = [];
                        view.isLoading = false;
                        view.trendTitle = "";
                        view.getuserClients();
                      }
                    });
                })
                .catch((error) => {
                  if (!view.sectorAPILoading) view.isLoading = false;
                  console.error(error);
                });
            } else {
              view.selected = [];
              if (!view.sectorAPILoading) view.isLoading = false;
            }
          });
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select trend",
        });
      }
    },
    traverseSectorCombinations: function (i, order) {
      console.log(order);
      var sectorsData = this.sectorDatas;
      let toObjOrder = "";
      let thisObj = "";
      let thisObjOrder = "";
      let toObj = "";
      (toObjOrder =
        order === true ? parseInt(i) - parseInt(1) : parseInt(i) + parseInt(1)),
        (thisObj = sectorsData[i]),
        (thisObjOrder = i),
        (toObj = sectorsData[toObjOrder]);

      if (
        typeof sectorsData[thisObjOrder] === "undefined" ||
        typeof sectorsData[toObjOrder] === "undefined"
      ) {
        return;
      }
      sectorsData[thisObjOrder] = toObj;
      sectorsData[toObjOrder] = thisObj;
      console.log(sectorsData);
      this.sectorDatas = [];
      this.sectorDatas = sectorsData;
      console.log(this.sectorDatas);
      // this.updateSubmittableSectors(sectorsData);
    },
    removeSectorCombination: function (i) {
      console.log("removing ...");
      console.log(i);
      let view = this,
        sectorsData = view.sectorDatas;
      if (sectorsData.length == 0) {
        sectorDatas = [];
        // view.updateSubmittableSectors(sectorsData);
        this.previous_selected_sector_combination.splice(0, 1);
      } else {
        if (i > -1) {
          sectorsData.splice(i, 1);
          this.previous_selected_sector_combination.splice(i, 1);
          //console.log(i);
        }
        // view.updateSubmittableSectors(sectorsData);
      }
    },
    findCateg(atchid) {
      let view = this;
      console.log("findCateg", atchid);
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secCategory = [];
          console.log("findCateg", res.data.payload);
          // console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload);
            view.secCategory = [];
            view.secCategory = filtered;
          }
        });
      view.selected_category = "";
      view.selected_subcategory = "";
      view.selected_subsubcategory = "";
    },
    findSubCateg(atchid) {
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secSubCategory = [];
          console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload);
            view.secSubCategory = [];
            view.secSubCategory = filtered;
          }
        });
      view.selected_subcategory = "";
      view.selected_subsubcategory = "";
    },
    findSubSubCateg(atchid) {
      console.log("SSS", atchid);
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secSubSubCategory = [];
          console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload);
            view.secSubSubCategory = [];
            view.secSubSubCategory = filtered;
          }
        });
      view.selected_subsubcategory = "";
    },
    filter_allowed_sectors: function (response) {
      let allowed_sectors = this.$store.getters["Rawdata/getSectors"];
      let filtered = [];
      if (response.length) {
        response.forEach((o) => {
          if (allowed_sectors.includes(o.value)) {
            filtered.push(o);
          }
        });
      }
      return filtered;
    },
    showSectorData() {
      let selectedCombination = [];
      let check = this;
      selectedCombination.push(this.selected_sector);
      if (this.selected_category != "") {
        selectedCombination.push(this.selected_category);
      }
      if (this.selected_subcategory != "") {
        selectedCombination.push(this.selected_subcategory);
      }
      if (this.selected_subsubcategory != "") {
        selectedCombination.push(this.selected_subsubcategory);
      }
      let found = false;
      if (selectedCombination.length >= 1) {
        if (this.previous_selected_sector_combination.length > 0) {
          if (
            this.previous_selected_sector_combination.findIndex(
              (item) =>
                JSON.stringify(item) === JSON.stringify(selectedCombination)
            ) > -1
          ) {
            found = true;
          } else {
            found = false;
          }
        }
        if (found) {
          this.$swal.fire({
            icon: "error",
            title: "Duplicate Sector/Category",
            text: "Sector/Category has been already added.Please add other sector/category combination. ",
          });
          return;
        } else {
          let combination = [];
          this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
            .post(this.$url("COMPANYSEC"), { selectedIds: selectedCombination })
            .then((res) => {
              let allSectors = res.data.payload[0];
              allSectors.forEach(function (o, j) {
                combination.push({ id: o.id, name: o.name });
              });
              this.sectorDatas.push(combination);
              this.previous_selected_sector_combination.push(
                selectedCombination
              );
            });
        }
      }

      this.selected_sector = [];
      this.selected_category = [];
      this.selected_subcategory = [];
      this.selected_subsubcategory = [];
      this.secSubCategory = [];
    },
    addTrendValidation() {
      let view = this;
      view.errorMsg = "";
      if (view.sanitizeInput(view.specificTrendData.trend_name).length <= 0) {
        view.errorMsg = "Please Enter valid Trend Name";
        return false;
      } else if (view.specificTrendData.documentState === null) {
        view.errorMsg = "Please upload a file";
        return false;
      } else if (view.specificTrendData.audience_name.length <= 0) {
        view.errorMsg = "Please select audience";
        return false;
      } else if (view.sectorDatas.length <= 0) {
        view.errorMsg = "Please select sector";
        return false;
      } else {
        return true;
      }
    },
    editTrendValidation() {
      let view = this;

      if (view.sanitizeInput(view.specificTrendData.trend_name).length <= 0) {
        view.errorMsg = "Please Enter Valid Trend Name";
        return false;
      } else if (view.specificTrendData.audience_name.length <= 0) {
        view.errorMsg = "Please select audience";
        return false;
      } else if (view.sectorDatas.length <= 0) {
        view.errorMsg = "Please select sector";
        return false;
      } else {
        return true;
      }
    },
    addNewTrend() {
      let view = this;
      console.log("All data need to convert into formdata");
      let manage_reports_url = view.$url("TREND_ADMIN");
      let sector = view.sectorDatas.map((sublist) =>
        sublist.map((item) => item.id)
      );

      let formData = new FormData();
      if (view.specificTrendData.documentState)
        formData.append("file", view.specificTrendData.documentState);

      if (JSON.stringify(sector))
        formData.append("sector_id", JSON.stringify(sector));

      if (this.sanitizeInput(view.specificTrendData.trend_name).length > 0)
        formData.append(
          "trend_name",
          this.sanitizeInput(view.specificTrendData.trend_name)
        );

      if (view.specificTrendData.audience_name)
        formData.append(
          "audience_id",
          JSON.stringify(view.specificTrendData.audience_name)
        );

      if (view.specificTrendData.countrySelectedModel)
        formData.append(
          "country_id",
          view.specificTrendData.countrySelectedModel
        );

      if (view.specificTrendData.datesort)
        formData.append("trend_date", view.specificTrendData.datesort);

      if (view.addTrendValidation()) {
        view.isLoading = true;
        view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .post(manage_reports_url, formData, {
            headers: {
              // 'Authorization': accestoken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.previous_selected_sector_combination = [];
            console.log("RESponse", response);
            if (response.data.status_code == 200) {
              view.$swal
                .fire({
                  icon: "success",
                  title: "Success",
                  text: response.data.message,
                })
                .then((res) => {
                  console.log("test new record added");
                  if (res.isConfirmed) {
                    if (!view.sectorAPILoading) view.isLoading = false;
                    this.sortby = "";
                    this.sort = "";
                    this.getuserClients();
                  }
                });
              view.toggleAddEditTrend = false;
              view.reset();
            } else {
              view.isLoading = false;
              view.$swal.fire({
                icon: "error",
                title: "Failed",
                text: response.data.message,
              });
            }
          });
      } else {
        view.isLoading = false;
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: view.errorMsg,
        });
      }
    },
    sanitizeInput(text) {
      // Remove emojis using regex
      const noEmoji = text.replace(
        /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu,
        ""
      );
      // Trim whitespace
      return noEmoji.trim();
    },
    editTrend() {
      let view = this;
      console.log("All data need to convert into formdata");
      let manage_reports_url = view.$url("TREND_ADMIN");
      let sector = view.sectorDatas.map((sublist) =>
        sublist.map((item) => item.id)
      );
      // const str = view.specificTrendData.countrySelectedModel;
      // const num = parseInt(str);
      // const country = [num];
      // console.log(country);
      let formData = new FormData();
      if (view.specificTrendData.documentState != null) {
        formData.append("file", view.specificTrendData.documentState);
      }
      formData.append("trend_id", view.specificTrendData.trend_id);
      formData.append("sector_id", JSON.stringify(sector));
      formData.append(
        "trend_name",
        this.sanitizeInput(view.specificTrendData.trend_name)
      );
      formData.append(
        "audience_id",
        JSON.stringify(view.specificTrendData.audience_name)
      );
      formData.append(
        "country_id",
        view.specificTrendData.countrySelectedModel
      );
      formData.append("trend_date", view.specificTrendData.datesort);
      formData.append("file_path", view.specificTrendData.filePath);

      if (view.editTrendValidation()) {
        view.isLoading = true;
        view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .put(manage_reports_url, formData, {
            headers: {
              // 'Authorization': accestoken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("RESponse", response);
            if (response.data.status_code == 200) {
              view.toggleAddEditTrend = false;
              view.reset();
              view.$swal
                .fire({
                  icon: "success",
                  title: "Success",
                  text: response.data.message,
                })
            } else {  
              view.$swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.message,
              });
            }
            this.sectorDatas = [];
            if (!view.sectorAPILoading) view.isLoading = false;
          });
      } else {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: view.errorMsg,
        });
      }
    },
    reset() {
      var view = this;
      // view.filterName = "Member Email";
      // view.companyName = "";
      // view.emailAddress = "";
      // view.accStatus = "";
      // view.activeModle = 0;
      view.countrySelectedModel = "0";
      view.sdate = "";
      view.edate = "";
      view.selAudience = [];
      view.trendTitle = "";
      view.selSubSubCategory = [];
      view.selSubCategory = [];
      view.selCategory = [];
      view.selSectors = [];
      view.items=[];
      view.$store.dispatch("TrendReport/setPerPage", 10);
      view.$store.dispatch("TrendReport/setPage", parseInt(1));
      view.$store.dispatch("TrendReport/setResponsePayload", []);
      view.$store.dispatch("TrendReport/setTotal", "");
      view.$store.dispatch("TrendReport/setLastPage", 0);
      view.options.sortBy = [];
      view.options.sortDesc = [];
      view.sortby = undefined;
      view.sort = undefined;

      view.sortDesc = false;
  
      view.getuserClients();
    },
    findAndPrintChildren(data) {
      let view = this;
      let categories = [];
      let subcategories = [];
      let subsubcategories = [];

      data.forEach((item) => {
        if ("id" in item && "children" in item) {
          item.children.forEach((child) => {
            if ("children" in child) {
              if (child.children.length > 0) {
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(child.children)) {
                  if (value.children) {
                    if (value.children.length > 0) {
                      for (const [k, v] of Object.entries(value.children)) {
                        subsubcategories.push({
                          pid: item.id,
                          subpid: child.id,
                          subsubpid: value.id,
                          ...v,
                        });
                      }
                    }
                  }
                  subcategories.push({
                    pid: item.id,
                    subpid: child.id,
                    ...value,
                  });
                }
              }
            }
            categories.push({ pid: item.id, ...child });
          });
        }
      });
      view.category = categories;
      view.subcategory = subcategories;
      view.subsubcategory = subsubcategories;
      // console.log("cat", categories);
      // console.log("subcat", subcategories);
      // console.log("subsubcat", subsubcategories);
    },
    sectorSelect(e) {
      let view = this;
      const difference = view.sectorData.filter((item) => !e.includes(item));

      if (e.length < view.sectorData.length) {
        view.disselectSectors(difference[0]);
      } else {
        view.sectorsFnHandler(e);
      }
      view.sectorData = e;
    },
    selectCategory(e) {
      let view = this;
      let catSelectedRec = [];

      view.category.forEach((i) => {
        let found = e.includes(i.id);
        if (found) {
          catSelectedRec.push(i);
        }
      });
      view.selCategory = catSelectedRec;
      const difference = view.categoryData.filter((item) => !e.includes(item));
      console.log("diff", e.length, view.categoryData.length);

      if (e.length < view.categoryData.length) {
        view.disselectCategory(difference[0]);
      } else {
        view.categoryFnHandler(e);
      }
      view.categoryData = e;
    },
    selectSubCategory(e) {
      // console.log("SSs", e);
      let view = this;
      let catSelectedRec = [];
      view.subcategory.forEach((i) => {
        let found = e.includes(i.id);
        if (found) {
          catSelectedRec.push(i);
        }
      });
      view.selSubCategory = catSelectedRec;
      const difference = view.subcategoryData.filter(
        (item) => !e.includes(item)
      );

      if (e.length < view.subcategoryData.length) {
        view.disselectSubCategory(difference[0]);
      } else {
        view.subCategoryFnHandler(e);
      }
      view.subcategoryData = e;
    },
    selectSubSubCategory(e) {
      let view = this;
      let catSelectedRec = [];
      view.subsubcategory.forEach((i) => {
        let found = e.includes(i.id);
        if (found) {
          catSelectedRec.push(i);
        }
      });
      view.selSubSubCategory = catSelectedRec;
    },
    disselectSectors(id) {
      let view = this;
      // console.log(id, "Sector Dis", view.selCategory);
      let filteredCatselected = view.selCategory?.filter((f) => f.pid !== id);
      let removedCatselected = view.selCategory?.filter((f) => f.pid == id);
      // console.log(filteredCatselected, "Sector Dis", removedCatselected);
      removedCatselected.forEach((e) => {
        view.disselectCategory(e.id);
      });
      // console.log("Sector Dis..", view.filteredCatselected);
      let filteredCat = view.category_list?.filter((f) => f.pid !== id);
      let removedfilteredCat = view.category_list?.filter((f) => f.pid == id);
      // console.log(filteredCat, "Sector Dis..", removedfilteredCat);
      removedfilteredCat.forEach((e) => {
        view.disselectCategory(e.id);
      });
      // console.log("Sector Dis..", filteredCat);

      view.category_list = filteredCat;
      view.selCategory = filteredCatselected;
    },
    disselectCategory(id) {
      let view = this;
      // console.log(id, "Sector Dis.", view.selSubCategory);
      let filteredCatselected = view.selSubCategory?.filter(
        (f) => f.subpid !== id
      );
      let removedCatselected = view.selSubCategory?.filter(
        (f) => f.subpid == id
      );
      console.log(id, "Sector Dis.", removedCatselected);
      removedCatselected.forEach((e) => {
        view.disselectSubCategory(e.id);
      });
      let filteredCat = view.subcategory_list?.filter((f) => f.subpid !== id);
      let removedCat = view.subcategory_list?.filter((f) => f.subpid == id);
      removedCat.forEach((e) => {
        view.disselectSubCategory(e.id);
      });
      // console.log("Sector Dis..", filteredCat,id );
      view.subcategory_list = filteredCat;
      view.selSubCategory = filteredCatselected;
    },
    disselectSubCategory(id) {
      let view = this;

      let filteredCatselected = view.selSubSubCategory?.filter(
        (f) => f.subsubpid !== id
      );
      let filteredCats = view.subsubcategory_list?.filter(
        (f) => f.subsubpid !== id
      );
      console.log("Sector Dis", filteredCats);
      view.selSubSubCategory = filteredCatselected;
      view.subsubcategory_list = filteredCats;
    },

    sectorsFnHandler(evt) {
      let view = this;
      let cat = view.category;
      view.category_list = [];
      for (let i = 0; i < Object.entries(cat).length; i++) {
        evt.forEach((id) => {
          if (cat[i].pid === id) {
            view.category_list.push(cat[i]);
          }
        });
      }
    },
    categoryFnHandler(evt) {
      let view = this;
      let cat = view.subcategory;
      view.subcategory_list = [];
      for (let i = 0; i < Object.entries(cat).length; i++) {
        evt.forEach((id) => {
          if (cat[i].subpid === id) {
            view.subcategory_list.push(cat[i]);
          }
        });
      }
    },
    subCategoryFnHandler(evt) {
      let view = this;
      let cat = view.subsubcategory;
      view.subsubcategory_list = [];
      for (let i = 0; i < Object.entries(cat).length; i++) {
        evt.forEach((id) => {
          if (cat[i].subsubpid === id) {
            view.subsubcategory_list.push(cat[i]);
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.search-padding {
  padding: 0px !important;
}

#required {
  color: red;
}

#approved {
  color: red;
}

.custom-input {
  padding-top: 22px !important;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.menu-position {
  min-width: auto;
  top: 340px !important;
  transform-origin: left top;
  z-index: 204;
}
</style>
